import { Field } from 'react-final-form'

import { SelectValue } from 'common/components/BaseSelect'
import FormItem from 'common/components/formItem'
import Textarea from 'common/components/textarea/Textarea'
import { MergeTagListItem } from 'features/admin/accountSettings/api'

const validate = (value: string) => {
  if (!value) {
    return 'Required'
  }
  return undefined
}

type Props = {
  selectedCategory: SelectValue
  systemMergeTags?: MergeTagListItem[]
}

const CodeBlock = ({ systemMergeTags, selectedCategory }: Props) => {
  const code = systemMergeTags?.find(
    (tag) => tag.id === selectedCategory?.value
  )?.code

  return (
    <Field<string>
      name="merge_tag_code"
      initialValue={code ?? ''}
      validate={validate}
    >
      {({ input, meta }) => (
        <FormItem
          htmlFor={input.name}
          className="flex-none"
          error={meta.touched ? meta.error : undefined}
        >
          <Textarea
            showCharacterCount={true}
            maxLength={100}
            rows={4}
            placeholder="Add your code here"
            customClassName="bg-base-700 text-white font-normal rounded-sm p-4 
                            placeholder-base-300 outline-none w-full font-sourceCodePro"
            {...input}
          />
        </FormItem>
      )}
    </Field>
  )
}

export default CodeBlock
