import { useFlags } from 'launchdarkly-react-client-sdk'

import type { Props } from './Textarea'
import Textarea from './Textarea'
import TextareaDefault from './TextareaDefault'

const TextareaPerFlag = (props: Props) => {
  const flags = useFlags()

  return flags?.showL2Components ? (
    <Textarea {...props} />
  ) : (
    <TextareaDefault {...props} />
  )
}

export default TextareaPerFlag
