import { useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { unifiedFlowPaths } from 'app/navigation/paths'
import BannerContainer from 'workflow/common/bannerContainer/BannerContainer'
import NewVariantsBanner from 'workflow/common/newVariantsBanner/NewVariantsBanner'
import RawDataTabBanner from 'workflow/common/rawDataTabBanner'

import EmptyState from 'common/components/emptyState'
import ErrorPage from 'common/components/error/ErrorPage'
import Loader from 'common/components/loaders/Loader'
import useGetPersonalizationsQuery from 'common/components/personalizationReport/api/queries/useGetPersonalizationsQuery'
import TabWidget from 'common/components/tabWidget'
import Tooltip from 'common/components/Tooltip'
import useContent from 'common/hooks/useContent'
import useSelectedComponentElement from 'common/hooks/useSelectedComponentElement'

import useGetVariantsQuery from './api/queries/useGetVariantsQuery'
import PersonalizedResultsTable from './components/PersonalizedResultsTable'

const TAB_NAMES = {
  MATURE_DATA: 'MATURE DATA',
  RAW_DATA: 'RAW DATA',
}

const tabs = [
  {
    tabName: TAB_NAMES.MATURE_DATA,
    tabId: 'mature',
    tabIndex: '0',
    tabTooltip:
      'Data is 2+ days old. The open rate and click rate have stabilized and are therefore representative of final performance.',
  },
  {
    tabName: TAB_NAMES.RAW_DATA,
    tabId: 'raw',
    tabIndex: '1',
    tabTooltip:
      'All data is shown. This may include emails that have been sent recently. In this case, the open and click rate metrics for some variants may underestimate their final value.',
  },
]

const PersonalizationSummary = () => {
  const [activeTab, setActiveTab] = useState('0')

  const history = useHistory()
  const { content } = useContent()
  const { data: selectedComponentElement } = useSelectedComponentElement()

  const variantsToBeApprovedCount =
    selectedComponentElement?.personalized_ids_to_approve?.length ?? 0

  const personalizationsQuery = useGetPersonalizationsQuery()
  const currentPersonalization = personalizationsQuery.data?.find(
    (personalization) =>
      personalization.id === selectedComponentElement?.personalization_id
  )
  const personalizationRegion = currentPersonalization?.personalizationRegion

  const { data: personalizedVariants, ...personalizedVariantsQuery } =
    useGetVariantsQuery({
      personalizationId: selectedComponentElement?.personalization_id ?? '',
      personalizationRegion,
      resultType: tabs[activeTab].tabId,
    })

  if (personalizedVariantsQuery.isError) {
    return <ErrorPage />
  }

  return (
    <div className="overflow-x-hidden flex-1-0 bg-gold-40">
      {variantsToBeApprovedCount > 0 && (
        <BannerContainer>
          <NewVariantsBanner
            variantsToBeApprovedCount={variantsToBeApprovedCount}
            onClick={() => {
              history.push(
                generatePath(unifiedFlowPaths.review, {
                  contentId: content?._id,
                })
              )
            }}
          />
        </BannerContainer>
      )}
      <TabWidget
        activeTabKey={activeTab}
        data-testid="personalizationResultsWidget"
        destroyInactiveTabPane
        onTabClick={(tab) => {
          setActiveTab(tab)
        }}
        tabs={tabs.map(({ tabName, tabTooltip }) => ({
          name: (
            <Tooltip
              overlay={
                <span className="max-w-xs inline-block">{tabTooltip}</span>
              }
              placement="top"
            >
              <span>{tabName}</span>
            </Tooltip>
          ),
          content: (
            <>
              <hr className="w-full my-8 border-gold-400" />
              {tabName === TAB_NAMES.RAW_DATA && (
                <BannerContainer>
                  <RawDataTabBanner />
                </BannerContainer>
              )}
              {personalizedVariantsQuery.isLoading &&
              personalizedVariantsQuery.fetchStatus !== 'idle' ? (
                <Loader />
              ) : personalizedVariants?.length === 0 ||
                !personalizedVariants ? (
                <EmptyState title="Variant data is not available yet" />
              ) : (
                <PersonalizedResultsTable
                  resultType={tabs[activeTab].tabId}
                  data={personalizedVariants}
                  personalizationRegion={personalizationRegion}
                />
              )}
            </>
          ),
        }))}
        firstUse={false}
      />
    </div>
  )
}

export default PersonalizationSummary
