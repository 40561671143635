import { useCallback, useMemo, useState } from 'react'
import { Row } from 'react-table'
import cx from 'classnames'
import { getPersonalizedVariantsColumnParams } from 'workflow/common/getColumnParams'
import ResultsTableCell from 'workflow/common/resultsTableCell'

import ConfirmationModal from 'common/components/confirmationModal'
import { TableData } from 'common/components/table/Table'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppSelector } from 'common/hooks/redux'
import useSelectedComponentElement from 'common/hooks/useSelectedComponentElement'

import { PersonalizationRegion } from '../api/api'
import useUpdateVariantStatusMutation from '../api/mutations/useUpdateVariantStatusMutation'
import { VariantsQueryResult } from '../api/queries/useGetVariantsQuery'

type Props = {
  resultType: 'mature' | 'raw'
  data: VariantsQueryResult[]
  personalizationRegion: PersonalizationRegion | undefined
}

const PersonalizedResultsTable = ({
  resultType,
  data,
  personalizationRegion,
}: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const [variantToUpdate, setVariantToUpdate] =
    useState<{ id: string; variantStatus: string } | undefined>(undefined)

  const { data: selectedElement } = useSelectedComponentElement()
  const personalizationId = selectedElement?.personalization_id

  const combinationId = selectedElement?.personalized_variants?.find(
    (variant) => variant.variant_id === variantToUpdate?.id
  )?.combinationId

  const isBaselineVariant = !!selectedElement?.variants?.find(
    (variant) => variant.sl_id === variantToUpdate?.id
  )

  const { isLoading, mutate } = useUpdateVariantStatusMutation({
    accountId,
    personalizationId,
    personalizationRegion,
    resultType,
  })

  const isMatureTabAndAllZeroRecipients =
    (resultType === 'mature' &&
      data?.every((value) => value && value.num_recipients === 0)) ??
    false

  const tableColumns = useMemo(() => {
    const columns = [
      { title: 'Variants', dataIndex: 'variant', key: 'variant' },
      {
        title: 'Status',
        dataIndex: 'variant_status',
        key: 'variant_status',
      },
      ...(resultType === 'mature'
        ? [
            {
              title: 'Click rate uplift',
              dataIndex: 'click_uplift',
              key: 'click_uplift',
              isPercentage: true,
            },
            {
              title: 'Open rate uplift',
              dataIndex: 'open_uplift',
              key: 'open_uplift',
              isPercentage: true,
            },
          ]
        : []),
      {
        title: 'Sends',
        dataIndex: 'num_recipients',
        key: 'num_recipients',
      },
      { title: 'Opens', dataIndex: 'num_opens', key: 'num_opens' },
      { title: 'Clicks', dataIndex: 'num_clicks', key: 'num_clicks' },
    ]

    return [
      ...columns.map((column) => {
        const { dataIndex: accessor, key: id, title, isPercentage } = column

        return {
          id,
          Header: isPercentage ? `${title} (%)` : title,
          accessor,
          Cell: ({ row }) => {
            return (
              <ResultsTableCell
                isLoading={isLoading}
                setVariantToUpdate={setVariantToUpdate}
                data={row.original}
                accessor={accessor}
                resultType={resultType}
                isMatureTabAndAllZeroRecipients={
                  isMatureTabAndAllZeroRecipients
                }
              />
            )
          },
          ...getPersonalizedVariantsColumnParams(accessor),
        }
      }),
    ]
  }, [isLoading, resultType, isMatureTabAndAllZeroRecipients])

  const generateRowClassName = useCallback(
    <T extends TableData>({ row }: { row: Row<T> }) => {
      return cx({
        'bg-gold-40': !row.original.hasError,
      })
    },
    []
  )

  return (
    <>
      <TableWidget.Widget<VariantsQueryResult>
        data-testid="personalized-results-table"
        className="border-none bg-gold-40 mt-6"
        data={data}
        columns={tableColumns}
        initialState={{
          filters: [
            { id: 'variant_status', value: ['live', 'approved', 'pending'] },
          ],
        }}
        generateRowClassName={generateRowClassName}
      />
      <ConfirmationModal
        data-cy="change-variant-status-modal"
        data-testid="change-variant-status-modal"
        title="Change status of variant?"
        confirmationText="Do you really want to change the status of this variant?"
        cancelButtonText="Cancel"
        confirmButtonText="Yes, do it!"
        isLoading={isLoading}
        confirmButtonVariant="primary"
        open={variantToUpdate !== undefined}
        onConfirm={() => {
          if (!variantToUpdate) {
            return
          }

          if (
            personalizationId &&
            variantToUpdate.id &&
            variantToUpdate.variantStatus
          ) {
            mutate({
              accountId,
              personalizationId,
              combinationId,
              personalizationRegion,
              isBaselineVariant,
              variantId: variantToUpdate.id,
              status: variantToUpdate.variantStatus,
            })
          }

          setVariantToUpdate(undefined)
        }}
        onCancel={() => {
          setVariantToUpdate(undefined)
        }}
      />
    </>
  )
}

export default PersonalizedResultsTable
